import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

import styles from './styles.module.css';

class Lightbox extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      moving: false,
    };

    this.handleClose = this.handleClose.bind(this);
    this.animationStopping = this.animationStopping.bind(this);
    this.handleMove = this.handleMove.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);

  }

  componentDidMount() {

    document.addEventListener('keyup', this.handleKeyPress);

  }

  componentWillUnmount() {

    document.removeEventListener('keyup', this.handleKeyPress);

  }

  handleKeyPress(e) {

    if (e && e.key === 'Escape') this.handleClose();

  }

  handleClose(e) {

    if (e) {

      e.stopPropagation();
      e.preventDefault();

    }
    this.props.onCloseRequest();

  }

  animationStopping() {

    this.setState({
      moving: false,
    });

  }

  handleMove(direction) {

    this.setState({
      moving: direction,
    });

    if (direction === 'forwards') this.props.onMoveNextRequest();
    else this.props.onMovePrevRequest();

  }

  componentDidUpdate(prevProps) {

    let elem;
    let lbStyle = '';

    let { mainSrc, nextSrc, prevSrc } = this.props;

    if (this.state.moving && this.props.visible && this.props.mainSrc === prevProps.nextSrc) {

      lbStyle = styles.movingForwards;
      nextSrc = this.props.mainSrc;
      mainSrc = this.props.prevSrc;

    } else if (
      this.state.moving
      && this.props.visible
      && this.props.mainSrc === prevProps.prevSrc
    ) {

      lbStyle = styles.movingBackwards;
      prevSrc = this.props.mainSrc;
      mainSrc = this.props.nextSrc;

    }

    if (this.props.visible) {

      elem = (
        <div className={styles.lbContainerOuter}>
          <div
            className={styles.lbContainerInner}
            onClick={this.handleClose}
          >
            <img
              src={mainSrc}
              className={`${styles.lbImage} ${lbStyle}`}
              alt="lightboxImage"
              onClick={this.handleClose}
            />
            {
              this.state.moving === 'forwards'
              && (
                <img
                  src={nextSrc}
                  className={`${styles.lbOtherImage} ${styles.movingForwardsOther}`}
                  alt="lightboxImage"
                  onAnimationEnd={this.animationStopping}
                />
              )
            }
            {
              this.state.moving === 'backwards'
              && (
                <img
                  src={prevSrc}
                  className={`${styles.lbOtherImage} ${styles.movingBackwardsOther}`}
                  alt="lightboxImage"
                  onAnimationEnd={this.animationStopping}
                />
              )
            }
          </div>
          <div
            className={styles.prevButton}
            id="prevButton"
            onClick={this.state.moving ? null : () => this.handleMove('backwards')}
          >
            {<i className="entypo icon-left-open" />}
          </div>
          <div
            className={styles.nextButton}
            id="nextButton"
            onClick={this.state.moving ? null : () => this.handleMove('forwards')}
          >
            {<i className="entypo icon-right-open" />}
          </div>
        </div>);

    } else elem = <div/>;

    const modalRoot = document.getElementById('modal-root');
    if (modalRoot) ReactDOM.render(elem, modalRoot);

  }

  // eslint-disable-next-line class-methods-use-this
  render() {

    return null;

  }

}

Lightbox.propTypes = {
  mainSrc: PropTypes.string,
  nextSrc: PropTypes.string,
  prevSrc: PropTypes.string,
  onCloseRequest: PropTypes.func,
  onMovePrevRequest: PropTypes.func,
  onMoveNextRequest: PropTypes.func,
  visible: PropTypes.bool,
};

export default Lightbox;
